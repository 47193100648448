html, body, #root, .app {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: hidden;
}

@charset "UTF-8";
.app {
  text-align: center; }
  .app .app-header {
    background-color: #8da8bc;
    box-sizing: border-box;
    height: 50px;
    padding: 4px 0 4px 15px;
    color: white;
    text-align: left;
    line-height: 45px; }
  .app .app-title {
    display: inline;
    font-size: 1.5em;
    font-weight: 300;
    margin: 0 0 0 20px;
    padding: 0; }
  .app .app-logo {
    margin-top: 2px;
    float: left; }

.userPreview {
  height: calc(70% - 25px);
  position: relative;
  background: white; }
  .userPreview .letterPoolContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    border-bottom: 1px solid #cccccc; }
  .userPreview button.download {
    z-index: 20;
    position: absolute;
    bottom: 20px;
    right: 20px;
    border: 2px solid #00469b;
    background: transparent;
    padding: 10px 20px;
    font-size: 24px;
    color: #00469b;
    cursor: pointer;
    display: block;
    border: 2px solid #00469b;
    -webkit-transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s; }
    .userPreview button.download:hover {
      border-color: #e60004;
      color: #fff !important;
      background-color: transparent; }
      .userPreview button.download:hover:before {
        top: 0%;
        bottom: auto;
        height: 100%; }
    .userPreview button.download:before {
      display: block;
      position: absolute;
      left: 0px;
      bottom: 0px;
      height: 0px;
      width: 100%;
      z-index: -1;
      content: '';
      color: #fff !important;
      background: #e60004;
      -webkit-transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
      transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s; }

.userInput {
  display: block;
  box-sizing: border-box;
  margin: 0;
  height: calc(30% - 25px);
  background: #DDE4EA;
  width: 100%;
  border: none;
  padding: 20px;
  font-family: sans-serif;
  font-size: 50px;
  line-height: 70px;
  text-align: center;
  text-transform: uppercase; }

.buttonContainer {
  margin: auto;
  margin-top: 20px; }

button {
  margin: 0 10px; }

.bitmap-container {
  padding: 0 20px;
  white-space: nowrap;
  text-align: center;
  line-height: 0; }

.mode-preview img, .mode-preview span.letter {
  display: inline-block;
  height: 200px;
  width: auto;
  margin-bottom: -10px !important; }

.mode-preview .letter- {
  margin: 0 -25px; }

.mode-preview .letter-0 {
  margin: 0 -22px 0 -10px; }

.mode-preview .letter-1 {
  margin: 0 -30px 0 -10px; }

.mode-preview .letter-A, .mode-preview .letter-Ä, .mode-preview .letter-À, .mode-preview .letter-Á {
  margin: 0 -27px 0 -35px; }
  .mode-preview .letter-A + .letter-K, .mode-preview .letter-Ä + .letter-K, .mode-preview .letter-À + .letter-K, .mode-preview .letter-Á + .letter-K {
    margin-left: -20px; }

.mode-preview .letter-B {
  margin: 0 -25px 0 -15px; }

.mode-preview .letter-C {
  margin: 0 -28px 0 -10px; }
  .mode-preview .letter-C + .letter-O, .mode-preview .letter-C .letter-Ö, .mode-preview .letter-C .letter-Ò, .mode-preview .letter-C .letter-Ó {
    margin-left: -5px; }

.mode-preview .letter-D {
  margin: 0 -20px 0 -10px; }
  .mode-preview .letter-D + .letter-T {
    margin-left: -12px; }

.mode-preview .letter-E, .mode-preview .letter-EE, .mode-preview .letter-È, .mode-preview .letter-É {
  margin: 0 -24px 0 -10px; }
  .mode-preview .letter-E + .letter-A, .mode-preview .letter-E .letter-Ä, .mode-preview .letter-E .letter-À, .mode-preview .letter-E .letter-Á, .mode-preview .letter-EE + .letter-A, .mode-preview .letter-EE .letter-Ä, .mode-preview .letter-EE .letter-À, .mode-preview .letter-EE .letter-Á, .mode-preview .letter-È + .letter-A, .mode-preview .letter-È .letter-Ä, .mode-preview .letter-È .letter-À, .mode-preview .letter-È .letter-Á, .mode-preview .letter-É + .letter-A, .mode-preview .letter-É .letter-Ä, .mode-preview .letter-É .letter-À, .mode-preview .letter-É .letter-Á {
    margin-left: -45px; }
  .mode-preview .letter-E + .letter-F, .mode-preview .letter-EE + .letter-F, .mode-preview .letter-È + .letter-F, .mode-preview .letter-É + .letter-F {
    margin-left: -20px; }
  .mode-preview .letter-E + .letter-G, .mode-preview .letter-EE + .letter-G, .mode-preview .letter-È + .letter-G, .mode-preview .letter-É + .letter-G {
    margin-left: -5px; }
  .mode-preview .letter-E + .letter-N, .mode-preview .letter-E .letter-Ñ, .mode-preview .letter-EE + .letter-N, .mode-preview .letter-EE .letter-Ñ, .mode-preview .letter-È + .letter-N, .mode-preview .letter-È .letter-Ñ, .mode-preview .letter-É + .letter-N, .mode-preview .letter-É .letter-Ñ {
    margin-left: -15px; }
  .mode-preview .letter-E + .letter-Q, .mode-preview .letter-EE + .letter-Q, .mode-preview .letter-È + .letter-Q, .mode-preview .letter-É + .letter-Q {
    margin-left: -2px; }
  .mode-preview .letter-E + .letter-R, .mode-preview .letter-EE + .letter-R, .mode-preview .letter-È + .letter-R, .mode-preview .letter-É + .letter-R {
    margin-left: -25px; }
  .mode-preview .letter-E + .letter-S, .mode-preview .letter-EE + .letter-S, .mode-preview .letter-È + .letter-S, .mode-preview .letter-É + .letter-S {
    margin-left: -20px; }
  .mode-preview .letter-E + .letter-T, .mode-preview .letter-EE + .letter-T, .mode-preview .letter-È + .letter-T, .mode-preview .letter-É + .letter-T {
    margin-left: -15px; }
  .mode-preview .letter-E + .letter-W, .mode-preview .letter-EE + .letter-W, .mode-preview .letter-È + .letter-W, .mode-preview .letter-É + .letter-W {
    margin-left: -18px; }
  .mode-preview .letter-E + .letter-X, .mode-preview .letter-EE + .letter-X, .mode-preview .letter-È + .letter-X, .mode-preview .letter-É + .letter-X {
    margin-left: -25px; }

.mode-preview .letter-F, .mode-preview .letter-FF {
  margin: 0 -26px 0 -10px; }
  .mode-preview .letter-F + .letter-A, .mode-preview .letter-F letter-Ä, .mode-preview .letter-F .letter-À, .mode-preview .letter-F .letter-Á, .mode-preview .letter-FF + .letter-A, .mode-preview .letter-FF letter-Ä, .mode-preview .letter-FF .letter-À, .mode-preview .letter-FF .letter-Á {
    margin-left: -45px; }
  .mode-preview .letter-F + .letter-B, .mode-preview .letter-FF + .letter-B {
    margin-left: -20px; }
  .mode-preview .letter-F + .letter-E, .mode-preview .letter-F .letter-EE, .mode-preview .letter-F .letter-È, .mode-preview .letter-F .letter-É, .mode-preview .letter-FF + .letter-E, .mode-preview .letter-FF .letter-EE, .mode-preview .letter-FF .letter-È, .mode-preview .letter-FF .letter-É {
    margin-left: -15px; }
  .mode-preview .letter-F + .letter-F, .mode-preview .letter-FF + .letter-F {
    margin-left: -20px; }
  .mode-preview .letter-F + .letter-H, .mode-preview .letter-FF + .letter-H {
    margin-left: -20px; }
  .mode-preview .letter-F + .letter-J, .mode-preview .letter-FF + .letter-J {
    margin-left: -20px; }
  .mode-preview .letter-F + .letter-K, .mode-preview .letter-FF + .letter-K {
    margin-left: -20px; }
  .mode-preview .letter-F + .letter-T, .mode-preview .letter-FF + .letter-T {
    margin-left: -2px; }
  .mode-preview .letter-F + .letter-W, .mode-preview .letter-FF + .letter-W {
    margin-left: 0px; }
  .mode-preview .letter-F + .letter-Y, .mode-preview .letter-FF + .letter-Y {
    margin-left: 5px; }
  .mode-preview .letter-F + .letter-Z, .mode-preview .letter-FF + .letter-Z {
    margin-left: -10px; }

.mode-preview .letter-G {
  margin: 0 -20px 0 -10px; }
  .mode-preview .letter-G + .letter-H {
    margin-left: -20px; }

.mode-preview .letter-H {
  margin: 0 -22px 0 -20px; }
  .mode-preview .letter-H + .letter-T {
    margin-left: -2px; }

.mode-preview .letter-I, .mode-preview .letter-Ì, .mode-preview .letter-Í {
  margin: 0 -20px 0 -15px; }
  .mode-preview .letter-I + .letter-T, .mode-preview .letter-Ì + .letter-T, .mode-preview .letter-Í + .letter-T {
    margin-left: 0px; }

.mode-preview .letter-J {
  margin: 0 -10px; }
  .mode-preview .letter-J + .letter-A, .mode-preview .letter-J .letter-Ä, .mode-preview .letter-J .letter-À, .mode-preview .letter-J .letter-Á {
    margin-left: -50px; }
  .mode-preview .letter-J + .letter-T {
    margin-left: 0px; }

.mode-preview .letter-K {
  margin: 0 -25px 0 -15px; }
  .mode-preview .letter-K + .letter-A, .mode-preview .letter-K .letter-Ä, .mode-preview .letter-K .letter-À, .mode-preview .letter-K .letter-Á {
    margin-left: -40px; }
  .mode-preview .letter-K + .letter-F {
    margin-left: -15px; }
  .mode-preview .letter-K + .letter-T {
    margin-left: 0px; }
  .mode-preview .letter-K + .letter-Y {
    margin-left: -5px; }

.mode-preview .letter-L {
  margin: 0 -30px 0 -10px; }
  .mode-preview .letter-L + .letter-C {
    margin-left: -15px; }
  .mode-preview .letter-L + .letter-H {
    margin-left: -15px; }
  .mode-preview .letter-L + .letter-L {
    margin-left: -20px; }

.mode-preview .letter-M {
  margin: 0 -25px 0 -20px; }

.mode-preview .letter-N, .mode-preview .letter-Ñ {
  margin: 0 -22px 0 -15px; }
  .mode-preview .letter-N + .letter-T, .mode-preview .letter-Ñ + .letter-T {
    margin-left: -5px; }

.mode-preview .letter-O, .mode-preview .letter-Ö, .mode-preview .letter-Ò, .mode-preview .letter-Ó {
  margin: 0 -22px 0 -10px; }
  .mode-preview .letter-O + .letter-F, .mode-preview .letter-Ö + .letter-F, .mode-preview .letter-Ò + .letter-F, .mode-preview .letter-Ó + .letter-F {
    margin-left: -15px; }

.mode-preview .letter-P {
  margin: 0 -25px 0 -12px; }
  .mode-preview .letter-P + .letter-A, .mode-preview .letter-P .letter-Ä, .mode-preview .letter-P .letter-À, .mode-preview .letter-P .letter-Á {
    margin-left: -42px; }

.mode-preview .letter-Q {
  margin: 0 -5px 0 -10px; }
  .mode-preview .letter-Q + .letter-H {
    margin-left: -15px; }

.mode-preview .letter-R {
  margin: 0 -15px 0 -20px; }
  .mode-preview .letter-R + .letter-A, .mode-preview .letter-R .letter-Ä, .mode-preview .letter-R .letter-À, .mode-preview .letter-R .letter-Á {
    margin-left: -37px; }
  .mode-preview .letter-R + .letter-C {
    margin-left: -15px; }
  .mode-preview .letter-R + .letter-E, .mode-preview .letter-R .letter-EE, .mode-preview .letter-R .letter-È, .mode-preview .letter-R .letter-É {
    margin-left: -20px; }
  .mode-preview .letter-R + .letter-I, .mode-preview .letter-R .letter-Ì, .mode-preview .letter-R .letter-Í {
    margin-left: -18px; }
  .mode-preview .letter-R + .letter-O, .mode-preview .letter-R .letter-Ö, .mode-preview .letter-R .letter-Ò, .mode-preview .letter-R .letter-Ó {
    margin-left: -20px; }

.mode-preview .letter-S {
  margin: 0 -20px 0 -15px; }
  .mode-preview .letter-S + .letter-A, .mode-preview .letter-S .letter-Ä, .mode-preview .letter-S .letter-À, .mode-preview .letter-S .letter-Á {
    margin-left: -50px; }

.mode-preview .letter-T, .mode-preview .letter-TT {
  margin: 0 -20px 0 -10px; }
  .mode-preview .letter-T + .letter-A, .mode-preview .letter-T .letter-Ä, .mode-preview .letter-T .letter-À, .mode-preview .letter-T .letter-Á, .mode-preview .letter-TT + .letter-A, .mode-preview .letter-TT .letter-Ä, .mode-preview .letter-TT .letter-À, .mode-preview .letter-TT .letter-Á {
    margin-left: -48px; }
  .mode-preview .letter-T + .letter-H, .mode-preview .letter-TT + .letter-H {
    margin-left: -30px; }
  .mode-preview .letter-T + .letter-I, .mode-preview .letter-T .letter-Ì, .mode-preview .letter-T .letter-Í, .mode-preview .letter-TT + .letter-I, .mode-preview .letter-TT .letter-Ì, .mode-preview .letter-TT .letter-Í {
    margin-left: -10px; }
  .mode-preview .letter-T + .letter-Y, .mode-preview .letter-TT + .letter-Y {
    margin-left: 1px; }
  .mode-preview .letter-T + .letter-question, .mode-preview .letter-TT + .letter-question {
    margin-left: -5px; }

.mode-preview .letter-U, .mode-preview .letter-Ü, .mode-preview .letter-Ù, .mode-preview .letter-Ú {
  margin: 0 -20px 0 -10px; }
  .mode-preview .letter-U + .letter-C, .mode-preview .letter-Ü + .letter-C, .mode-preview .letter-Ù + .letter-C, .mode-preview .letter-Ú + .letter-C {
    margin-left: -4px; }
  .mode-preview .letter-U + .letter-T, .mode-preview .letter-Ü + .letter-T, .mode-preview .letter-Ù + .letter-T, .mode-preview .letter-Ú + .letter-T {
    margin-left: -4px; }

.mode-preview .letter-V {
  margin: 0 -10px 0 -10px; }
  .mode-preview .letter-V + .letter-A, .mode-preview .letter-V .letter-Ä, .mode-preview .letter-V .letter-À, .mode-preview .letter-V .letter-Á {
    margin-left: -55px; }
  .mode-preview .letter-V + .letter-B {
    margin-left: -20px; }
  .mode-preview .letter-V + .letter-F {
    margin-left: -15px; }

.mode-preview .letter-W {
  margin: 0 -20px 0 -10px; }
  .mode-preview .letter-W + .letter-E, .mode-preview .letter-W .letter-EE, .mode-preview .letter-W .letter-È, .mode-preview .letter-W .letter-É {
    margin-left: -18px; }

.mode-preview .letter-X {
  margin: 0 -15px 0 -20px; }
  .mode-preview .letter-X + .letter-E, .mode-preview .letter-X .letter-EE, .mode-preview .letter-X .letter-È, .mode-preview .letter-X .letter-É {
    margin-left: -15px; }

.mode-preview .letter-Y {
  margin: 0 -25px 0 -10px; }
  .mode-preview .letter-Y + .letter-A, .mode-preview .letter-Y .letter-Ä, .mode-preview .letter-Y .letter-À, .mode-preview .letter-Y .letter-Á {
    margin-left: -45px; }
  .mode-preview .letter-Y + .letter-B {
    margin-left: -20px; }
  .mode-preview .letter-Y + .letter-E, .mode-preview .letter-Y .letter-EE, .mode-preview .letter-Y .letter-È, .mode-preview .letter-Y .letter-É {
    margin-left: -15px; }

.mode-preview .letter-Z {
  margin: 0 -5px 0 -15px; }
  .mode-preview .letter-Z + .letter-E, .mode-preview .letter-Z .letter-EE, .mode-preview .letter-Z .letter-È, .mode-preview .letter-Z .letter-É {
    margin-left: -15px; }

.mode-preview .letter-quotes {
  margin: 0 -20px 0 10px; }

.mode-preview .letter-quotes-low {
  margin: 0 20px 0 -10px; }

.mode-preview .letter-apostrophe {
  margin: 0 -20px 0 10px; }

.mode-preview .letter-line {
  margin: 0 -10px 0 15px; }

.mode-preview .letter-excl-mark {
  margin: 0 -10px; }

.mode-preview .letter-points {
  margin: 0 -10px; }

.mode-preview .letter-question {
  margin: 0 -10px; }

.mode-preview .letter-point {
  margin: 0 -10px; }

.mode-preview .letter-comma {
  margin: 0 -10px; }

.mode-preview .letter-space {
  display: inline-block;
  width: 50px; }

.mode-full img, .mode-full span.letter {
  display: inline-block;
  height: 1060px;
  width: auto;
  margin-bottom: -10px !important; }

.mode-full .letter- {
  margin: 0 -132.5px; }

.mode-full .letter-0 {
  margin: 0 -116.6px 0 -53px; }

.mode-full .letter-1 {
  margin: 0 -159px 0 -53px; }

.mode-full .letter-A, .mode-full .letter-Ä, .mode-full .letter-À, .mode-full .letter-Á {
  margin: 0 -143.1px 0 -185.5px; }
  .mode-full .letter-A + .letter-K, .mode-full .letter-Ä + .letter-K, .mode-full .letter-À + .letter-K, .mode-full .letter-Á + .letter-K {
    margin-left: -106px; }

.mode-full .letter-B {
  margin: 0 -132.5px 0 -79.5px; }

.mode-full .letter-C {
  margin: 0 -148.4px 0 -53px; }
  .mode-full .letter-C + .letter-O, .mode-full .letter-C .letter-Ö, .mode-full .letter-C .letter-Ò, .mode-full .letter-C .letter-Ó {
    margin-left: -26.5px; }

.mode-full .letter-D {
  margin: 0 -106px 0 -53px; }
  .mode-full .letter-D + .letter-T {
    margin-left: -63.6px; }

.mode-full .letter-E, .mode-full .letter-EE, .mode-full .letter-È, .mode-full .letter-É {
  margin: 0 -127.2px 0 -53px; }
  .mode-full .letter-E + .letter-A, .mode-full .letter-E .letter-Ä, .mode-full .letter-E .letter-À, .mode-full .letter-E .letter-Á, .mode-full .letter-EE + .letter-A, .mode-full .letter-EE .letter-Ä, .mode-full .letter-EE .letter-À, .mode-full .letter-EE .letter-Á, .mode-full .letter-È + .letter-A, .mode-full .letter-È .letter-Ä, .mode-full .letter-È .letter-À, .mode-full .letter-È .letter-Á, .mode-full .letter-É + .letter-A, .mode-full .letter-É .letter-Ä, .mode-full .letter-É .letter-À, .mode-full .letter-É .letter-Á {
    margin-left: -238.5px; }
  .mode-full .letter-E + .letter-F, .mode-full .letter-EE + .letter-F, .mode-full .letter-È + .letter-F, .mode-full .letter-É + .letter-F {
    margin-left: -106px; }
  .mode-full .letter-E + .letter-G, .mode-full .letter-EE + .letter-G, .mode-full .letter-È + .letter-G, .mode-full .letter-É + .letter-G {
    margin-left: -26.5px; }
  .mode-full .letter-E + .letter-N, .mode-full .letter-E .letter-Ñ, .mode-full .letter-EE + .letter-N, .mode-full .letter-EE .letter-Ñ, .mode-full .letter-È + .letter-N, .mode-full .letter-È .letter-Ñ, .mode-full .letter-É + .letter-N, .mode-full .letter-É .letter-Ñ {
    margin-left: -79.5px; }
  .mode-full .letter-E + .letter-Q, .mode-full .letter-EE + .letter-Q, .mode-full .letter-È + .letter-Q, .mode-full .letter-É + .letter-Q {
    margin-left: -10.6px; }
  .mode-full .letter-E + .letter-R, .mode-full .letter-EE + .letter-R, .mode-full .letter-È + .letter-R, .mode-full .letter-É + .letter-R {
    margin-left: -132.5px; }
  .mode-full .letter-E + .letter-S, .mode-full .letter-EE + .letter-S, .mode-full .letter-È + .letter-S, .mode-full .letter-É + .letter-S {
    margin-left: -106px; }
  .mode-full .letter-E + .letter-T, .mode-full .letter-EE + .letter-T, .mode-full .letter-È + .letter-T, .mode-full .letter-É + .letter-T {
    margin-left: -79.5px; }
  .mode-full .letter-E + .letter-W, .mode-full .letter-EE + .letter-W, .mode-full .letter-È + .letter-W, .mode-full .letter-É + .letter-W {
    margin-left: -95.4px; }
  .mode-full .letter-E + .letter-X, .mode-full .letter-EE + .letter-X, .mode-full .letter-È + .letter-X, .mode-full .letter-É + .letter-X {
    margin-left: -132.5px; }

.mode-full .letter-F, .mode-full .letter-FF {
  margin: 0 -137.8px 0 -53px; }
  .mode-full .letter-F + .letter-A, .mode-full .letter-F letter-Ä, .mode-full .letter-F .letter-À, .mode-full .letter-F .letter-Á, .mode-full .letter-FF + .letter-A, .mode-full .letter-FF letter-Ä, .mode-full .letter-FF .letter-À, .mode-full .letter-FF .letter-Á {
    margin-left: -238.5px; }
  .mode-full .letter-F + .letter-B, .mode-full .letter-FF + .letter-B {
    margin-left: -106px; }
  .mode-full .letter-F + .letter-E, .mode-full .letter-F .letter-EE, .mode-full .letter-F .letter-È, .mode-full .letter-F .letter-É, .mode-full .letter-FF + .letter-E, .mode-full .letter-FF .letter-EE, .mode-full .letter-FF .letter-È, .mode-full .letter-FF .letter-É {
    margin-left: -79.5px; }
  .mode-full .letter-F + .letter-F, .mode-full .letter-FF + .letter-F {
    margin-left: -106px; }
  .mode-full .letter-F + .letter-H, .mode-full .letter-FF + .letter-H {
    margin-left: -106px; }
  .mode-full .letter-F + .letter-J, .mode-full .letter-FF + .letter-J {
    margin-left: -106px; }
  .mode-full .letter-F + .letter-K, .mode-full .letter-FF + .letter-K {
    margin-left: -106px; }
  .mode-full .letter-F + .letter-T, .mode-full .letter-FF + .letter-T {
    margin-left: -10.6px; }
  .mode-full .letter-F + .letter-W, .mode-full .letter-FF + .letter-W {
    margin-left: 0px; }
  .mode-full .letter-F + .letter-Y, .mode-full .letter-FF + .letter-Y {
    margin-left: 26.5px; }
  .mode-full .letter-F + .letter-Z, .mode-full .letter-FF + .letter-Z {
    margin-left: -53px; }

.mode-full .letter-G {
  margin: 0 -106px 0 -53px; }
  .mode-full .letter-G + .letter-H {
    margin-left: -106px; }

.mode-full .letter-H {
  margin: 0 -116.6px 0 -106px; }
  .mode-full .letter-H + .letter-T {
    margin-left: -10.6px; }

.mode-full .letter-I, .mode-full .letter-Ì, .mode-full .letter-Í {
  margin: 0 -106px 0 -79.5px; }
  .mode-full .letter-I + .letter-T, .mode-full .letter-Ì + .letter-T, .mode-full .letter-Í + .letter-T {
    margin-left: 0px; }

.mode-full .letter-J {
  margin: 0 -53px; }
  .mode-full .letter-J + .letter-A, .mode-full .letter-J .letter-Ä, .mode-full .letter-J .letter-À, .mode-full .letter-J .letter-Á {
    margin-left: -265px; }
  .mode-full .letter-J + .letter-T {
    margin-left: 0px; }

.mode-full .letter-K {
  margin: 0 -132.5px 0 -79.5px; }
  .mode-full .letter-K + .letter-A, .mode-full .letter-K .letter-Ä, .mode-full .letter-K .letter-À, .mode-full .letter-K .letter-Á {
    margin-left: -212px; }
  .mode-full .letter-K + .letter-F {
    margin-left: -79.5px; }
  .mode-full .letter-K + .letter-T {
    margin-left: 0px; }
  .mode-full .letter-K + .letter-Y {
    margin-left: -26.5px; }

.mode-full .letter-L {
  margin: 0 -159px 0 -53px; }
  .mode-full .letter-L + .letter-C {
    margin-left: -79.5px; }
  .mode-full .letter-L + .letter-H {
    margin-left: -79.5px; }
  .mode-full .letter-L + .letter-L {
    margin-left: -106px; }

.mode-full .letter-M {
  margin: 0 -132.5px 0 -106px; }

.mode-full .letter-N, .mode-full .letter-Ñ {
  margin: 0 -116.6px 0 -79.5px; }
  .mode-full .letter-N + .letter-T, .mode-full .letter-Ñ + .letter-T {
    margin-left: -26.5px; }

.mode-full .letter-O, .mode-full .letter-Ö, .mode-full .letter-Ò, .mode-full .letter-Ó {
  margin: 0 -116.6px 0 -53px; }
  .mode-full .letter-O + .letter-F, .mode-full .letter-Ö + .letter-F, .mode-full .letter-Ò + .letter-F, .mode-full .letter-Ó + .letter-F {
    margin-left: -79.5px; }

.mode-full .letter-P {
  margin: 0 -132.5px 0 -63.6px; }
  .mode-full .letter-P + .letter-A, .mode-full .letter-P .letter-Ä, .mode-full .letter-P .letter-À, .mode-full .letter-P .letter-Á {
    margin-left: -222.6px; }

.mode-full .letter-Q {
  margin: 0 -26.5px 0 -53px; }
  .mode-full .letter-Q + .letter-H {
    margin-left: -79.5px; }

.mode-full .letter-R {
  margin: 0 -79.5px 0 -106px; }
  .mode-full .letter-R + .letter-A, .mode-full .letter-R .letter-Ä, .mode-full .letter-R .letter-À, .mode-full .letter-R .letter-Á {
    margin-left: -196.1px; }
  .mode-full .letter-R + .letter-C {
    margin-left: -79.5px; }
  .mode-full .letter-R + .letter-E, .mode-full .letter-R .letter-EE, .mode-full .letter-R .letter-È, .mode-full .letter-R .letter-É {
    margin-left: -106px; }
  .mode-full .letter-R + .letter-I, .mode-full .letter-R .letter-Ì, .mode-full .letter-R .letter-Í {
    margin-left: -95.4px; }
  .mode-full .letter-R + .letter-O, .mode-full .letter-R .letter-Ö, .mode-full .letter-R .letter-Ò, .mode-full .letter-R .letter-Ó {
    margin-left: -106px; }

.mode-full .letter-S {
  margin: 0 -106px 0 -79.5px; }
  .mode-full .letter-S + .letter-A, .mode-full .letter-S .letter-Ä, .mode-full .letter-S .letter-À, .mode-full .letter-S .letter-Á {
    margin-left: -265px; }

.mode-full .letter-T, .mode-full .letter-TT {
  margin: 0 -106px 0 -53px; }
  .mode-full .letter-T + .letter-A, .mode-full .letter-T .letter-Ä, .mode-full .letter-T .letter-À, .mode-full .letter-T .letter-Á, .mode-full .letter-TT + .letter-A, .mode-full .letter-TT .letter-Ä, .mode-full .letter-TT .letter-À, .mode-full .letter-TT .letter-Á {
    margin-left: -254.4px; }
  .mode-full .letter-T + .letter-H, .mode-full .letter-TT + .letter-H {
    margin-left: -159px; }
  .mode-full .letter-T + .letter-I, .mode-full .letter-T .letter-Ì, .mode-full .letter-T .letter-Í, .mode-full .letter-TT + .letter-I, .mode-full .letter-TT .letter-Ì, .mode-full .letter-TT .letter-Í {
    margin-left: -53px; }
  .mode-full .letter-T + .letter-Y, .mode-full .letter-TT + .letter-Y {
    margin-left: 5.3px; }
  .mode-full .letter-T + .letter-question, .mode-full .letter-TT + .letter-question {
    margin-left: -26.5px; }

.mode-full .letter-U, .mode-full .letter-Ü, .mode-full .letter-Ù, .mode-full .letter-Ú {
  margin: 0 -106px 0 -53px; }
  .mode-full .letter-U + .letter-C, .mode-full .letter-Ü + .letter-C, .mode-full .letter-Ù + .letter-C, .mode-full .letter-Ú + .letter-C {
    margin-left: -21.2px; }
  .mode-full .letter-U + .letter-T, .mode-full .letter-Ü + .letter-T, .mode-full .letter-Ù + .letter-T, .mode-full .letter-Ú + .letter-T {
    margin-left: -21.2px; }

.mode-full .letter-V {
  margin: 0 -53px 0 -53px; }
  .mode-full .letter-V + .letter-A, .mode-full .letter-V .letter-Ä, .mode-full .letter-V .letter-À, .mode-full .letter-V .letter-Á {
    margin-left: -291.5px; }
  .mode-full .letter-V + .letter-B {
    margin-left: -106px; }
  .mode-full .letter-V + .letter-F {
    margin-left: -79.5px; }

.mode-full .letter-W {
  margin: 0 -106px 0 -53px; }
  .mode-full .letter-W + .letter-E, .mode-full .letter-W .letter-EE, .mode-full .letter-W .letter-È, .mode-full .letter-W .letter-É {
    margin-left: -95.4px; }

.mode-full .letter-X {
  margin: 0 -79.5px 0 -106px; }
  .mode-full .letter-X + .letter-E, .mode-full .letter-X .letter-EE, .mode-full .letter-X .letter-È, .mode-full .letter-X .letter-É {
    margin-left: -79.5px; }

.mode-full .letter-Y {
  margin: 0 -132.5px 0 -53px; }
  .mode-full .letter-Y + .letter-A, .mode-full .letter-Y .letter-Ä, .mode-full .letter-Y .letter-À, .mode-full .letter-Y .letter-Á {
    margin-left: -238.5px; }
  .mode-full .letter-Y + .letter-B {
    margin-left: -106px; }
  .mode-full .letter-Y + .letter-E, .mode-full .letter-Y .letter-EE, .mode-full .letter-Y .letter-È, .mode-full .letter-Y .letter-É {
    margin-left: -79.5px; }

.mode-full .letter-Z {
  margin: 0 -26.5px 0 -79.5px; }
  .mode-full .letter-Z + .letter-E, .mode-full .letter-Z .letter-EE, .mode-full .letter-Z .letter-È, .mode-full .letter-Z .letter-É {
    margin-left: -79.5px; }

.mode-full .letter-quotes {
  margin: 0 -106px 0 53px; }

.mode-full .letter-quotes-low {
  margin: 0 106px 0 -53px; }

.mode-full .letter-apostrophe {
  margin: 0 -106px 0 53px; }

.mode-full .letter-line {
  margin: 0 -53px 0 79.5px; }

.mode-full .letter-excl-mark {
  margin: 0 -53px; }

.mode-full .letter-points {
  margin: 0 -53px; }

.mode-full .letter-question {
  margin: 0 -53px; }

.mode-full .letter-point {
  margin: 0 -53px; }

.mode-full .letter-comma {
  margin: 0 -53px; }

.mode-full .letter-space {
  display: inline-block;
  width: 265px; }

.download-generator {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; }
  .download-generator .bitmap-container {
    padding: 200px; }

